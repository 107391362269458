<template>
  <div id="App">
    <Logo></Logo>
    <van-divider>会员信息</van-divider>
    <van-row type="flex">
      <van-col span="4"></van-col>
      <van-col span="18">
        <van-field
          label="卡号"
          :value="hykh"
          label-align="left"
          left-icon="vip-card"
          label-width="3em"
          readonly
        />
        <van-field
          label="姓名"
          :value="hyxm"
          label-align="left"
          left-icon="manager"
          label-width="3em"
          readonly
        >
          <template #button>
            <van-button size="small" type="info" @click="show = true"
              >修改密码</van-button
            >
          </template>
        </van-field>
        <van-field
          label="余额"
          :value="'￥' + ksye"
          label-align="left"
          left-icon="gold-coin"
          label-width="3em"
          readonly
        />
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
    <van-row gutter="20" style="margin-top: 25px">
      <van-col span="2"></van-col>
      <van-col span="20">
        <van-button round block type="danger" @click="onLoginOut()">
          {{ title }}
        </van-button>
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
    <van-action-sheet v-model="show" title="修改密码">
      <div class="content">
        <van-form @submit="onSubmit">
          <van-field
            v-model="oldUserPwd"
            name="原密码"
            label="原密码"
            placeholder="原密码"
            type="password"
            :rules="[{ required: true, message: '请填写原密码' }]"
          />
          <van-field
            v-model="newUserPwd"
            name="新密码"
            label="新密码"
            placeholder="新密码"
            type="password"
            :rules="[{ required: true, message: '请填写新密码' }]"
          />
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit">
              确认
            </van-button>
          </div>
        </van-form>
      </div>
    </van-action-sheet>
    <Tabbar></Tabbar>
  </div>
</template>
<style>
.content {
  padding: 16px 16px;
}
</style>

<script>
import { mapState } from "vuex";
import Tabbar from "../components/Tabbar.vue";
import Logo from "../components/Logo.vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      title: "退出",
      userInfoOut: {
        userName: "",
        userCardID: "",
        loginType: 0,
        token: "",
        wechatUserCode: ""
      },
      show: false,
      loading: false,
      oldUserPwd: "",
      newUserPwd: "",
      hykh: "",
      hyxm: "",
      ksye: ""
    };
  },
  components: {
    Tabbar,
    Logo
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    onLoginOut() {
      var that = this;
      if (that.userInfo.wechatUserCode == "") {
        that.$store.commit("setUserInfo", that.userInfoOut);
        that.$router.push("/");
      } else {
        that
          .$axios({
            method: "post",
            url: "/api/Main",
            data: {
              Method: "GetWechatLoginOut",
              Params: [that.userInfo.wechatUserCode]
            },
            headers: {
              Authorization: "Bearer " + that.userInfo.token
            }
          })
          .then(response => {
            if (response.data.status == "200") {
              that.$store.commit("setUserInfo", that.userInfoOut);
              that.$router.push("/");
            } else {
              Dialog({ message: response.data.msg });
            }
          })
          .catch(error => {
            Dialog({ message: error.message });
          });
      }
    },
    onSubmit() {
      var that = this;
      that
        .$axios({
          method: "post",
          url: "/api/Main",
          data: {
            Method: "API_setNewPwd",
            Params: [that.userInfo.userCardID, that.oldUserPwd, that.newUserPwd]
          },
          headers: {
            Authorization: "Bearer " + that.userInfo.token
          }
        })
        .then(response => {
          if (response.data.status == "200") {
            Dialog({ message: response.data.Table[0].TXT });
          } else {
            Dialog({ message: response.data.msg });
          }
        })
        .catch(error => {
          Dialog({ message: error.message });
        });
      that.show = false;
    }
  },
  created: function() {
    this.loading = true;
    var that = this;
    that
      .$axios({
        method: "post",
        url: "/api/Main",
        data: {
          Method: "API_getHYXX",
          Params: [that.userInfo.userCardID]
        },
        headers: {
          Authorization: "Bearer " + that.userInfo.token
        }
      })
      .then(response => {
        if (response.data.status == "200") {
          that.hykh = response.data.Table[0].HYKH;
          that.hyxm = response.data.Table[0].HYXM;
          that.ksye = response.data.Table[0].KSYE;
        } else {
          Dialog({ message: response.data.msg });
          that.finished = true;
        }
      })
      .catch(error => {
        //弹出失败提醒
        Dialog({ message: error.message });
        that.finished = true;
      });
  }
};
</script>
