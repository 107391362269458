<template>
  <div id="app">
    <van-row type="flex" justify="center" style="margin-top: 56px">
      <van-col span="4"></van-col>
      <van-col span="16">
        <van-image width="100%" :src="photoPath" />
      </van-col>
      <van-col span="4"></van-col>
    </van-row>
    <van-row type="flex" style="margin-bottom: 16px">
      <van-col span="2"></van-col>
      <van-col span="20">
        <van-field
          :label="sysTitle"
          label-width="100%"
          label-align="center"
          size="large"
        />
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["sysTitle", "photoPath"]),
  },
};
</script>
