<template>
  <div id="app">
    <van-tabbar v-model="pageActive" placeholder>
      <van-tabbar-item icon="search" @click="onsearch()"
        >我的预约</van-tabbar-item
      >
      <van-tabbar-item icon="add-o" @click="onAdd()">新增预约</van-tabbar-item>
      <van-tabbar-item icon="setting-o" @click="onSetting()"
        >个人设置</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<style lang="less">
.van-tabbar,
.van-tabbar__placeholder {
  height: 66px;
}
.van-icon {
  font-size:larger;
}
</style>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      pageActive: 0
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    onsearch() {
      if (this.$route.path != "/selyylist") {
        this.$router.push("/selyylist");
      }
    },
    onSetting() {
      if (this.$route.path != "/loginout") {
        this.$router.push("/loginout");
      }
    },
    onAdd() {
      if (this.$route.path != "/addyy") {
        this.$router.push("/addyy");
      }
    }
  },
  created: function() {
    if (this.userInfo.token == "" && this.$route.path != "/") {
      this.$router.push("/");
    }
    if (this.$route.path == "/loginout") {
      this.pageActive = 2;
    } else if (this.$route.path == "/selyylist") {
      this.pageActive = 0;
    } else {
      this.pageActive = 1;
    }
  }
};
</script>